import { styled } from 'goober';

export const ResourceLinkContainer = styled('div')`
    &#resource-container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
`;

export const ResourceLinkList = styled('ul')`
    &#dropdown-resource-link-list {
        display: flex;
        flex-flow: column wrap;
        align-content: space-between;
        justify-content: space-between;
        font-size: 18px;
        padding-bottom: 80px;

        @media( min-width: 768px ) {
            height: 580px;
            font-size: 22px;
        }

        @media( min-width: 992px ) {
            height: 450px;
        }
    }
`;

export const SectionLabel = styled('h2')`
    &#dropdown-resource-label {
        color: ${ ({theme})=>theme.linkColor };
        margin: 0 0 24px;
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        line-height: 1;
    }
`;

export const ResourceLinkItem = styled('li')`
    display: flex;
    flex-basis: 44px;
    align-items: center;

    @media( min-width: 768px ) {
        flex-basis: 62px;
    }
`;

export const ResourceLinkItemAnchor = styled('span')`
    a {
        text-decoration: none !important;
        display: inline-block;
        padding: 2px;
        font-weight: 700;
        line-height: 1.2em;
        transition: color .2s ease-out 50ms, box-shadow .1s ease-in;
        color: ${ ({theme})=>theme.linkColor };

        svg {
          max-width: 1em;
        }

        &:focus {
            outline: 2px solid ${ ({theme})=>theme.linkColor };
            outline-offset: 4px;
        }

        &:hover {
            color: ${ ({theme})=>theme.linkInteractColor };
        }
    }
`;

export const ResourceLinksCTAButton = styled('li')`
    &#resources-cta-button {
      padding-top: 20px;

      @media( min-width: 768px) {
          padding-top: 0px;
      }

        a.cta-button {
            display: inline-flex;
            align-items: center;
            height: 36px;
            font-size: 0.875rem;
            letter-spacing: 0.3px;
            max-width: 290px;
            overflow: hidden;
            white-space: nowrap;
            padding: 16px;
            background-color: transparent;
            border: 2px solid ${ ({theme})=>theme.buttonColor };
            color: ${ ({theme})=>theme.buttonColor };
            box-shadow: none;
            font-weight: 700;
            transition: background-color ease 0.3s, color ease 0.5s;
            margin-top: calc((44px - 36px) / 2);
            margin-bottom: calc((44px - 36px) / 2);

            @media( min-width: 768px) {
                margin-top: calc((62px - 36px) / 2);
                margin-bottom: calc((62px - 36px) / 2);
            }

            &:hover,
            &:focus {
                border-color: ${ ({theme})=>theme.buttonColor };
                background-color: ${ ({theme})=>theme.buttonColor };
                box-shadow: none;
                color: ${ ({theme})=>theme.resultsPaneBg };
            }
        }
    }
`;
